/*--------------- ADD MAIN FONT -------------------------*/

/**
 * @license
 * MyFonts Webfont Build ID 3315240, 2016-11-30T09:09:47-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FF Mark Web Pro by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/mark/pro-regular/
 * 
 * Webfont: FF Mark Web Pro Bold by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/mark/pro-bold/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3315240
 * Licensed pageviews: 50,000
 * Webfonts copyright: 2013 published by FontShop International GmbH
 * 
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/329628");


@font-face {
    font-family: 'FFMarkWebPro';
    src: url('../../fonts/329628_0_0.eot');
    src: url('../../fonts/329628_0_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/329628_0_0.woff2') format('woff2'),url('../../fonts/329628_0_0.woff') format('woff'),url('../../fonts/329628_0_0.ttf') format('truetype');
}


@font-face {
    font-family: 'FFMarkWebPro';
    font-weight: 700;
    src: url('../../fonts/329628_1_0.eot');
    src: url('../../fonts/329628_1_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/329628_1_0.woff2') format('woff2'),url('../../fonts/329628_1_0.woff') format('woff'),url('../../fonts/329628_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'FFMarkWebProBold';
    src: url('../../fonts/329628_1_0.eot');
    src: url('../../fonts/329628_1_0.eot?#iefix') format('embedded-opentype'),url('../../fonts/329628_1_0.woff2') format('woff2'),url('../../fonts/329628_1_0.woff') format('woff'),url('../../fonts/329628_1_0.ttf') format('truetype');
}
